import React, {useState, useEffect, useRef} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

import {KTSVG} from '../../../../_metronic/helpers'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import {format, parseISO, differenceInHours} from 'date-fns'
import DatePicker from 'react-datepicker'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'

import {useThemeMode} from '../../../../_metronic/partials'

import usePostApi from '../../custom_hooks/usePostApi'
import {DateBasicFormat} from '../../DateHelpers'

const AddLeaveTypes = ({}) => {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {mode} = useThemeMode()
  const {execute} = usePostApi()

  const intl = useIntl()

  const validationSchema = Yup.object().shape({
    leave_type_name: Yup.string().required('Leave Type Name is Required'),
    effective_start_date: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.START_DATE_IS_REQUIRED'})}`
    ),
    effective_end_date: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.END_DATE_IS_REQUIRED'})}`
    ),
    leave_type_category: Yup.string().required('Category is Required'),
    // no_of_days: Yup.string().required(
    //   `${intl.formatMessage({id: 'ERR.MSG.NO_OF_DAYS_IS_REQUIRED'})}`
    // ),
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const initialValues = {
    leave_type_name: '',
    leave_type_category: '',
    carry_forward_allowed: '',
    carry_forward_max_allowed_days: '',
    effective_start_date: '',
    effective_end_date: '',
  }

  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    try {
      const url = `${baseUrl}/leave/types`
      const organizationId = secureLocalStorage.getItem('organization_id')

      const formData = {
        organization_id: organizationId,

        leave_type_name: values.leave_type_name,
        leave_type_category: values.leave_type_category,
        carry_forward_allowed: values.carry_forward_allowed ? 'Yes' : 'No',
        carry_forward_max_allowed_days: values.carry_forward_max_allowed_days
          ? values.carry_forward_max_allowed_days
          : 0,
        effective_start_date: DateBasicFormat(values.effective_start_date),
        effective_end_date: DateBasicFormat(values.effective_end_date),
      }
      console.log(formData)
      await execute(url, 'POST', formData)

      resetForm()
      Swal.fire({
        title: 'Leave Created Successfully',
        text: 'Changes have been saved',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, handleChange}) => (
            <Form>
              <div className='card-header' id='side_activities_header'>
                <h3 className='card-title fw-bolder text-dark'>Create LeaveType</h3>
                <div className='card-toolbar '>
                  <React.Fragment>
                    <div
                      className='btn btn-icon btn-sm btn-active-danger'
                      id='side_activities_close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </React.Fragment>
                </div>
              </div>

              <div className='card-body position-relative' id='side_activities_body'>
                <div>
                  <div
                    id='side_activities_scroll'
                    className='position-relative scroll-y me-n5 pe-5 w-400px h-500px'
                    data-kt-scroll='true'
                    data-kt-scroll-height='auto'
                    data-kt-scroll-wrappers='#side_activities_body'
                    data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                    data-kt-scroll-offset='5px'
                  >
                    {/* <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {intl.formatMessage({id: 'LABEL.SELECT_PROJECT'})}
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field
                          as='select'
                          className='form-select form-select-solid'
                          id='floatingInput'
                          name='projectType'
                          value={values.projectType || ''}
                          onChange={(e) => setFieldValue('projectType', e.target.value)}
                          placeholder={intl.formatMessage({id: 'LABEL.SELECT_PROJECT'})}
                        >
                          <option value=''>
                            {intl.formatMessage({id: 'LABEL.SELECT_PROJECT'})}
                          </option>
                          {projectDetails?.map((projectType) => (
                            <option key={projectType.project_id} value={projectType.project_name}>
                              {projectType.project_name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name='projectType' component='div' className='text-danger' />
                      </div>
                    </div> */}

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>Leave Type</label>
                      <div className='col-lg-8 text-start'>
                        <Field
                          type='text'
                          name='activity'
                          className='form-control form-control-solid'
                          value={values.leave_type_name || ''}
                          onChange={(e) => setFieldValue('leave_type_name', e.target.value)}
                          placeholder='Leave Type'
                        />
                        <ErrorMessage
                          name='leave_type_name'
                          component='div'
                          className='text-danger'
                        />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        Select Category
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field
                          as='select'
                          className='form-select form-select-solid'
                          id='floatingInput'
                          name='leave_type_category'
                          value={values.leave_type_category}
                          onChange={handleChange}
                        >
                          <option value=''>Select Category</option>
                          <option value='Paid'>paid</option>
                          <option value='Compensatory_off'>compensatory_off</option>
                          <option value='Unpaid'>unpaid</option>
                        </Field>
                        <ErrorMessage
                          name='leave_type_category'
                          component='div'
                          className='text-danger'
                        />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {' '}
                        {intl.formatMessage({id: 'LABEL.START_DATE'})}
                      </label>

                      <div className='col-lg-8 text-start'>
                        <Field name='effective_start_date'>
                          {({form, field}) => {
                            const {setFieldValue} = form
                            const {value} = field

                            const handleChange = (date) => {
                              setFieldValue('effective_start_date', date)
                            }

                            return (
                              <div
                                className={`react-datepicker-fullwidth-wrapper test ${
                                  mode === 'dark' ? 'dark-mode' : 'light-mode'
                                }`}
                              >
                                <DatePicker
                                  selected={value}
                                  minDate={new Date()}
                                  isClearable
                                  showYearDropdown
                                  scrollableYearDropdown
                                  onChange={(date) => {
                                    handleChange(date)
                                  }}
                                  placeholderText='Select Start Date'
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  dateFormat='dd/MM/yyyy'
                                  className='form-control form-control-solid'
                                  popperPlacement='bottom'
                                />
                              </div>
                            )
                          }}
                        </Field>
                        <ErrorMessage
                          name='effective_start_date'
                          component='div'
                          className='text-danger'
                        />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {intl.formatMessage({id: 'LABEL.END_DATE'})}
                      </label>
                      <div className='col-lg-8 text-start'>
                        <Field name='effective_end_date'>
                          {({form, field}) => {
                            const {setFieldValue} = form
                            const {value} = field

                            const handleChange = (date) => {
                              setFieldValue('effective_end_date', date)
                            }

                            let minDate = null
                            if (values && values.effective_start_date) {
                              const starttime = new Date(values.effective_start_date)
                              minDate = starttime.setDate(starttime.getDate() + 1)
                            }

                            return (
                              <div
                                className={`react-datepicker-fullwidth-wrapper test ${
                                  mode === 'dark' ? 'dark-mode' : 'light-mode'
                                }`}
                              >
                                <DatePicker
                                  selected={value}
                                  isClearable
                                  showYearDropdown
                                  scrollableYearDropdown
                                  onChange={(date) => {
                                    handleChange(date)
                                  }}
                                  placeholderText='Select End Date'
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  dateFormat='dd/MM/yyyy'
                                  className='form-control form-control-solid'
                                  popperPlacement='bottom'
                                  minDate={minDate}
                                />
                              </div>
                            )
                          }}
                        </Field>
                        <ErrorMessage
                          name='effective_end_date'
                          component='div'
                          className='text-danger'
                        />
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        Carry Forward
                      </label>
                      <div className='col-lg-8'>
                        <div className='form-check form-check-solid form-switch'>
                          <Field
                            className='form-check-input w-40px h-20px'
                            type='checkbox'
                            name='carry_forward_allowed'
                            id='toggle'
                          />
                          <span
                            className={`me-4 fw-bold ${
                              values.carry_forward_allowed ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {values.carry_forward_allowed ? 'Yes' : 'No'}
                          </span>
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name=' carry_forward_allowed'
                          />
                        </div>
                      </div>
                    </div>
                    {values.carry_forward_allowed && (
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          Carry Forward Allowed Days
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='carry_forward_max_allowed_days'
                            className='form-control form-control-solid'
                            value={
                              values.carry_forward_allowed
                                ? values.carry_forward_max_allowed_days
                                : 0
                            }
                            onChange={(e) =>
                              setFieldValue('carry_forward_max_allowed_days', e.target.value)
                            }
                            placeholder='Max Allowed Days'
                          />
                          <ErrorMessage
                            name='carry_forward_max_allowed_days'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                    )}

                    <div className='card-footer p-0 py-5 text-end'>
                      <button
                        type='submit'
                        className='btn btn-sm btn-light-primary ms-2'
                        onClick={() => {
                          setIsSubmitting(true)
                        }}
                      >
                        {intl.formatMessage({id: 'BTN.ADD'})}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AddLeaveTypes
