import {useState} from 'react'
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage'

const usePostApi = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  const execute = async (url, method, data, id = null) => {
    setLoading(true)
    setError(null)
    setResponse(null)
    try {
      let emp_id
      let user_id
      let auth_token
      let organization_id
      const userInfo = secureLocalStorage.getItem('user_info')
      if (userInfo) {
        ;({emp_id, user_id, auth_token, organization_id} = userInfo)
      }
      const headers = {
        Authorization: `Bearer ${auth_token}`,
        i18n_language: 'en',
        user_id: user_id,
        organization_id: organization_id,
        employee_id: id ? id : emp_id,
        zylk_license_key: 'CCFZ-76C0-2710-E09D-4FCE-80A0-E720-A051-F8F4',
      }
      const result = await axios({
        method,
        url,
        data,
        headers,
      })
      setLoading(false)
      setResponse(result.data)
      return result.data
    } catch (err) {
      setLoading(false)
      setError(err)
      setResponse(err.response ? err.response.data : err.message)
      throw err
    }
  }

  return {loading, error, execute, response}
}

export default usePostApi
