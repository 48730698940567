import React, {useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {useIntl} from 'react-intl'
import useApiCall from '../../custom_hooks/useApiCall'
import {Table, Pagination} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import usePageTitle from '../../custom_hooks/usePageTitle'
import {useEffect} from 'react'
import Context from '../../../../_metronic/partials/layout/activity-drawer/context'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useLocation} from 'react-router-dom'

const DataTable = ({columns, data, loading}) => {
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.LEAVE_TYPES'}))
  const [inputVal, setInputVal] = useState('')
  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const activityMatches =
            each.leave_type && each.leave_type.toLowerCase().includes(inputVal.toLowerCase())

          return activityMatches
        })
      : []
  }, [inputVal, data])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [1, 2, 5, 10, 20, 30, 40, 50]

  return (
    <Context.Consumer>
      {(value) => {
        const {id, updateId} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='d-flex flex-wrap flex-stack mb-6'>
                  <h3 className='fw-bolder my-2 default-cursor'>
                    {intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.LEAVE_TYPES'})}
                  </h3>

                  <div className='d-flex my-2'>
                    <div className='d-flex align-items-center position-relative me-4'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm w-150px ps-9'
                        placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                      <button
                        className='btn btn-sm btn-light-primary mx-2'
                        id='side_activities_toggle'
                        onClick={() => {
                          updateId({
                            type: 'create_leave_type',
                          })
                        }}
                      >
                        <KTIcon iconName='plus' className='fs-3' />
                        CREATE LEAVE TYPE
                      </button>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='tab-content p-2 pt-0'>
                    <div className='card-body tab-pane fade show active table-responsive'>
                      <table
                        className='table table-row-dashed table-row-gray-300 align-middle'
                        {...getTableProps()}
                      >
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-150px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.LEAVE_TYPE'})}
                            </th>
                            <th className='min-w-150px text-center default-cursor'>
                              {intl.formatMessage({id: 'LABEL.CATEGORY'})}
                            </th>
                            <th className='min-w-150px text-center default-cursor'>
                              {intl.formatMessage({id: 'LABEL.CARRY_FORWARD'})}
                            </th>

                            <th className='min-w-120px text-center default-cursor'>
                              {intl.formatMessage({id: 'LABEL.MAX_ALLOWED_BALANCE'})}
                            </th>
                          </tr>
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page && page.length > 0 ? (
                            page.map((row) => {
                              prepareRow(row)
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => (
                                    <td className='text-center' {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  ))}
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td className='card'>
                                <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                  {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                </p>
                              </td>
                              <td>
                                <p></p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      {filteredData.length > 10 && (
                        <Pagination>
                          <Pagination.First
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                          />
                          <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                          {[...Array(pageCount)].map((_, i) => (
                            <Pagination.Item
                              key={i}
                              active={i === pageIndex}
                              onClick={() => gotoPage(i)}
                            >
                              {i + 1}
                            </Pagination.Item>
                          ))}

                          <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                          <Pagination.Last
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                          />

                          <div className='d-flex align-items-center mx-5'>
                            <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                            <select
                              className='form-select form-select-solid fw-bold w-75px me-2'
                              value={pageSize}
                              onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                              {pageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Pagination>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1 className='mb-5'>No leaves found.</h1>

                  <button
                    className='btn btn-sm btn-light-primary'
                    id='side_activities_toggle'
                    onClick={() => {
                      updateId({
                        type: 'create_leave_type',
                      })
                    }}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    Create Leave Type
                  </button>
                </div>
              </div>
            )}
            <SideDrawer value={id} />
          </>
        )
      }}
    </Context.Consumer>
  )
}

const LeaveTypesTable = () => {
  const {data: filteredDataByDate, isLoading, fetchData} = useApiCall(`/leave/types`)
  useEffect(() => {
    fetchData()
  }, [])
  const location = useLocation()

  useEffect(() => {
    const targetElement = document.getElementById('side_activities')
    if (targetElement && location.pathname === '/leave-management/leave-types') {
      const body = document.querySelector('body')
      const observer = new MutationObserver(() => {
        if (!body.hasAttribute('data-kt-drawer-side-activities')) {
          fetchData()
        }
      })
      observer.observe(targetElement, {attributes: true})
      return () => {
        observer.disconnect()
      }
    }
  }, [location.pathname, fetchData])
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'img',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 dafault-cursor'>
                {row.original.leave_type}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Category',
        accessor: 'category',
        Cell: ({row}) => {
          return (
            <p className='text-dark  fw-bold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.category.toUpperCase()}
            </p>
          )
        },
      },

      {
        Header: 'Approval',
        accessor: 'approval',
        Cell: ({row}) => {
          const carryForward = row.original.carry_forward

          return (
            <div className='default-cursor'>
              {carryForward === 'yes' ? (
                <KTIcon iconName='archive-tick' className='text-success fs-2x me-2' />
              ) : (
                <KTIcon iconName='cross' className='text-danger fs-2x me-2' />
              )}
            </div>
          )
        },
      },

      {
        Header: 'Entitlement',
        accessor: 'entitlemen',
        Cell: ({row}) => {
          return (
            <p className='text-dark  fw-bold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.max_allowed} Days
            </p>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={filteredDataByDate ? filteredDataByDate : []}
      loading={isLoading}
    />
  )
}

export default LeaveTypesTable
