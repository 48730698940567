import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import Context from '../../../../_metronic/partials/layout/activity-drawer/context'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import DateFormatter from '../../DateFormatter'
import secureLocalStorage from 'react-secure-storage'
import {useLocation} from 'react-router-dom'
import usePageTitle from '../../custom_hooks/usePageTitle'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../../../_metronic/partials'
import DatePicker from 'react-datepicker'
import Swal from 'sweetalert2'
import useApiCall from '../../custom_hooks/useApiCall'
import {baseUrl} from '../../core'
import usePostApi from '../../custom_hooks/usePostApi'
const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)

  const {mode} = useThemeMode()

  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'LABEL.MY_REQUESTS'}))

  const filteredData = useMemo(() => {
    return data?.filter((each) => each.request_type.toLowerCase().includes(inputVal.toLowerCase()))
  }, [data, inputVal])

  const filteredDataByDate = useMemo(() => {
    if (!selectedDate) {
      return filteredData
      // No date selected, return the current filtered data
    }

    // Adjust the selected date to account for the time zone offset
    const selectedDateStr = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10) // Convert selected date to 'yyyy-MM-dd' format

    return filteredData.filter((each) => {
      return each.created_timestamp.slice(0, 10) === selectedDateStr
    })
  }, [selectedDate, filteredData])
  // Create separate datasets for active and inactive records

  // Table instances for active and inactive datasets
  const activeData = useMemo(() => {
    return filteredDataByDate?.filter((request) => request.request_status === 'HR Pending')
  }, [filteredDataByDate])

  const inactiveData = useMemo(() => {
    return filteredDataByDate?.filter((request) => request.request_status === 'Approved')
  }, [filteredDataByDate])

  const RejectedData = useMemo(() => {
    return filteredDataByDate?.filter((request) => request.request_status === 'Rejected')
  }, [filteredDataByDate])

  const revokedData = useMemo(() => {
    return filteredDataByDate?.filter((request) => request.request_status === 'Revoked')
  }, [filteredDataByDate])

  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)
  const RejectedDataInstance = useTable({columns, data: RejectedData}, usePagination)
  const RevokedDataInstance = useTable({columns, data: revokedData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  const RejectedPage = RejectedDataInstance.page
  const RejectedPageCount = RejectedDataInstance.pageCount
  const RejectedPageIndex = RejectedDataInstance.state.pageIndex
  const RejectedPageSize = RejectedDataInstance.state.pageSize

  const RevokedPage = RevokedDataInstance.page
  const RevokedPageCount = RevokedDataInstance.pageCount
  const RevokedPageIndex = RevokedDataInstance.state.pageIndex
  const RevokedPageSize = RevokedDataInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }

  const gotoRejectedPage = (pageIndex) => {
    RejectedDataInstance.gotoPage(pageIndex)
  }

  const setRejectedPage = (pageSize) => {
    RejectedDataInstance.setPageSize(pageSize)
  }
  const gotoRevokedPage = (pageIndex) => {
    RevokedDataInstance.gotoPage(pageIndex)
  }

  const setRevokedPage = (pageSize) => {
    RevokedDataInstance.setPageSize(pageSize)
  }

  const pageOptions = [5, 10, 20, 30, 40, 50]

  // Render the component
  return (
    <Context.Consumer>
      {(value) => {
        const {id, updateId} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='d-flex'>
                  <div className='d-flex flex-wrap flex-stack mb-6'>
                    <h3 className='fw-bolder my-2'>
                      {' '}
                      {intl.formatMessage({id: 'LABEL.MY_REQUESTS'})}
                    </h3>
                  </div>
                </div>
                <>
                  <div className='card'>
                    <div className='card-header border-0 pt-5'>
                      <div className='d-flex flex-wrap flex-stack'>
                        <div className='fw-bolder'>
                          <ul className='nav'>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_1'
                              >
                                {intl.formatMessage({id: 'LABEL.PENDING'})}
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_2'
                              >
                                {intl.formatMessage({id: 'LABEL.APPROVED'})}
                              </a>
                            </li>

                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_3'
                              >
                                {intl.formatMessage({id: 'LABEL.REJECTED'})}
                              </a>
                            </li>

                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_4'
                              >
                                Revoked
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='card-toolbar'>
                        <div className='d-flex align-items-center position-relative me-4'>
                          <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                          <input
                            type='search'
                            id='kt_filter_search'
                            className='form-control form-control-white form-control-sm w-150px ps-9'
                            placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                            value={inputVal}
                            onChange={(e) => setInputVal(e.target.value)}
                          />
                        </div>

                        {/* <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
      <KTIcon iconName='calendar' className='fs-2 m-0' />
    </button> */}

                        <div
                          className={`react-datepicker-fullwidth-wrapper test me-4 ${
                            mode === 'dark' ? 'dark-mode' : 'light-mode'
                          }`}
                        >
                          <DatePicker
                            className='form-control form-control-sm'
                            selected={selectedDate}
                            isClearable
                            showYearDropdown
                            scrollableYearDropdown
                            dateFormat='dd/MM/yyyy'
                            onChange={(date) => setSelectedDate(date)}
                            placeholderText={intl.formatMessage({id: 'LABEL.SELECT_DATE'})}
                            showMonthDropdown
                            useShortMonthInDropdown
                          />
                        </div>

                        <a
                          href='#'
                          className='btn  btn-light-primary mx-2'
                          id='side_activities_toggle'
                          onClick={() => {
                            updateId({
                              type: 'raise_request',
                            })
                          }}
                        >
                          <KTIcon iconName='plus' className='fs-3' />
                          {intl.formatMessage({id: 'LABEL.RAISE_REQUESTS'})}
                        </a>
                      </div>
                    </div>
                    <div className='tab-content p-2 pt-0'>
                      <div
                        className='card-body tab-pane fade show active table-responsive'
                        id='kt_table_widget_4_tab_1'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...activeTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px default-cursor'>
                                {intl.formatMessage({id: 'LABEL.REQUEST_TYPE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.REQUEST_DETAILS'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.OLD_VALUE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.NEW_VALUE'})}
                              </th>

                              <th className='min-w-140px  text-muted text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                              </th>
                              <th className='min-w-140px text-start default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>

                          <tbody {...activeTableInstance.getTableBodyProps()}>
                            {filteredData.length > 0 &&
                            filteredDataByDate.length > 0 &&
                            activePage.length > 0 ? (
                              activePage.map((row) => {
                                activeTableInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                                <td>
                                  <p></p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoActivePage(0)}
                              disabled={!activeTableInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={activeTableInstance.previousPage}
                              disabled={!activeTableInstance.canPreviousPage}
                            />
                            {[...Array(activePageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === activePageIndex}
                                onClick={() => gotoActivePage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={activeTableInstance.nextPage}
                              disabled={!activeTableInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoActivePage(activePageCount - 1)}
                              disabled={!activeTableInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={activePageSize}
                                onChange={(e) => setActivePageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>

                      <div
                        className='card-body tab-pane fade  table-responsive'
                        id='kt_table_widget_4_tab_2'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...inactiveTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px default-cursor'>
                                {intl.formatMessage({id: 'LABEL.REQUEST_TYPE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.REQUEST_DETAILS'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.OLD_VALUE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.NEW_VALUE'})}
                              </th>

                              <th className='min-w-140px  text-muted text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                              </th>

                              <th className='min-w-140px text-start default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>

                          <tbody {...inactiveTableInstance.getTableBodyProps()}>
                            {filteredData.length > 0 &&
                            filteredDataByDate.length > 0 &&
                            inactivePage.length > 0 ? (
                              inactivePage.map((row) => {
                                inactiveTableInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                                <td>
                                  <p></p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoInactivePage(0)}
                              disabled={!inactiveTableInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={inactiveTableInstance.previousPage}
                              disabled={!inactiveTableInstance.canPreviousPage}
                            />
                            {[...Array(inactivePageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === inactivePageIndex}
                                onClick={() => gotoInactivePage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={inactiveTableInstance.nextPage}
                              disabled={!inactiveTableInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoInactivePage(inactivePageCount - 1)}
                              disabled={!inactiveTableInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={inactivePageSize}
                                onChange={(e) => setInactivePageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>
                      <div
                        className='card-body tab-pane fade show  table-responsive'
                        id='kt_table_widget_4_tab_3'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...RejectedDataInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px default-cursor'>
                                {intl.formatMessage({id: 'LABEL.REQUEST_TYPE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.REQUEST_DETAILS'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.OLD_VALUE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.NEW_VALUE'})}
                              </th>

                              <th className='min-w-140px  text-muted text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                              </th>
                              <th className='min-w-140px  text-muted text-start default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                              {/*
    <th className='min-w-100px text-start default-cursor'>
      {intl.formatMessage({id: 'LABEL.STATUS'})}
    </th> */}
                            </tr>
                          </thead>

                          <tbody {...RejectedDataInstance.getTableBodyProps()}>
                            {filteredData.length > 0 &&
                            filteredDataByDate.length > 0 &&
                            RejectedPage.length > 0 ? (
                              RejectedPage.map((row) => {
                                RejectedDataInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                                <td>
                                  <p></p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoRejectedPage(0)}
                              disabled={!RejectedDataInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={RejectedDataInstance.previousPage}
                              disabled={!RejectedDataInstance.canPreviousPage}
                            />
                            {[...Array(RejectedPageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === RejectedPageIndex}
                                onClick={() => gotoRejectedPage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={RejectedDataInstance.nextPage}
                              disabled={!RejectedDataInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoRejectedPage(RejectedPageCount - 1)}
                              disabled={!RejectedDataInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={RejectedPageSize}
                                onChange={(e) => setRejectedPage(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>
                      <div
                        className='card-body tab-pane fade  table-responsive'
                        id='kt_table_widget_4_tab_4'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...RevokedDataInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px default-cursor'>
                                {intl.formatMessage({id: 'LABEL.REQUEST_TYPE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.REQUEST_DETAILS'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.OLD_VALUE'})}
                              </th>

                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.NEW_VALUE'})}
                              </th>

                              <th className='min-w-140px  text-muted text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                              </th>

                              <th className='min-w-140px text-start default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>

                          <tbody {...RevokedDataInstance.getTableBodyProps()}>
                            {filteredData.length > 0 &&
                            filteredDataByDate.length > 0 &&
                            RevokedPage.length > 0 ? (
                              RevokedPage.map((row) => {
                                RevokedDataInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                                <td>
                                  <p></p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoInactivePage(0)}
                              disabled={!RevokedDataInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={RevokedDataInstance.previousPage}
                              disabled={!RevokedDataInstance.canPreviousPage}
                            />
                            {[...Array(RevokedPageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === RevokedPageIndex}
                                onClick={() => gotoRevokedPage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={RevokedDataInstance.nextPage}
                              disabled={RevokedDataInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoRevokedPage(RevokedPageCount - 1)}
                              disabled={!RevokedDataInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={RevokedPageSize}
                                onChange={(e) => setRevokedPage(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1> {intl.formatMessage({id: 'ERR.MSG.NO_REQUEST_DATA_FOUND'})}</h1>

                  <button
                    className='btn btn-sm btn-light-primary mt-3'
                    id='side_activities_toggle'
                    onClick={() => {
                      updateId({
                        type: 'raise_request',
                      })
                    }}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    {intl.formatMessage({id: 'LABEL.RAISE_REQUESTS'})}
                  </button>
                </div>
              </div>
            )}
            <SideDrawer value={id} />
          </>
        )
      }}
    </Context.Consumer>
  )
}

const SelfServiceTable = () => {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const intl = useIntl()
  const {data: TableData, isLoading, fetchData} = useApiCall(`/selfService/${emp_id}`)
  const {execute} = usePostApi()
  const location = useLocation()

  useEffect(() => {
    const targetElement = document.getElementById('side_activities')
    if (targetElement && location.pathname === '/employee-self-service/self-service-request') {
      const body = document.querySelector('body')
      const observer = new MutationObserver(() => {
        if (!body.hasAttribute('data-kt-drawer-side-activities')) {
          fetchData()
        }
      })
      observer.observe(targetElement, {attributes: true})
      return () => {
        observer.disconnect()
      }
    }
  }, [location.pathname, fetchData])

  const columns = useMemo(
    () => [
      {
        Header: 'Request Type',
        accessor: 'Request Type',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.request_type}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Request Details',
        accessor: 'Request Details',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.request_details}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Old Value',
        accessor: 'Old Value',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.old_value}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'New Value',
        accessor: 'New Value',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.new_value}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Raised Date',
        accessor: 'Raised Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.created_timestamp.slice(0, 10))}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          const handleSubmit = async (values, formikHelpers) => {
            // const {resetForm} = formikHelpers
            try {
              const organizationId = secureLocalStorage.getItem('organization_id')
              const url = `${baseUrl}/selfService/status/${row.original.request_id}`
              const formData = {
                organization_id: organizationId,

                request_status: 'Revoked',
              }

              await execute(url, 'PATCH', formData)
              // resetForm()

              Swal.fire({
                title: 'Revoked the request successfully.',
                text: 'You can now track the status of the Request in the “My Requests” section..',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-light-primary btn-sm',
                },
              })
            } catch (error) {
              console.error(error)
              Swal.fire({
                icon: 'error',
                title: intl.formatMessage({id: 'LABEL.OOPS'}),
                text: intl.formatMessage({id: 'LABEL.SOMETHING_WENT_WRONG'}),
                customClass: {
                  confirmButton: 'btn btn-light-primary btn-sm',
                },
              })
            }
          }

          const handleInfoIconClick = () => {
            Swal.fire({
              title: 'Are you sure you want to cancel this request?',
              text: 'This action cannot be reversed.',

              icon: 'info',
              showCancelButton: true,
              confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
              cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
              customClass: {
                confirmButton: 'btn btn-light-primary btn-sm',
                cancelButton: 'btn btn-sm',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                handleSubmit()
              }
            })
          }

          return (
            <div>
              <>
                <div className='text-start'>
                  <div className='btn btn-light-primary btn-sm' onClick={handleInfoIconClick}>
                    <KTIcon iconName='cross' className='fs-3' />
                  </div>
                </div>
              </>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable columns={columns} data={TableData !== null ? TableData : []} loading={isLoading} />
  )
}

export default SelfServiceTable
