import {Route, Routes, useLocation} from 'react-router-dom'
import {useState} from 'react'

import MyLeavesNew from './my_leaves/MyLeavesMain'

import LeaveTypesTable from './leave_types/LeaveTypesTable'

import LeaveNavbar from './LeaveNavbar'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import LeaveEligibility from './leave_eligibility/LeaveEligibility'
import HolidaysPageMain from './holidays/HolidaysPageMain'
import Context from '../../../_metronic/partials/layout/activity-drawer/context'
import LeaveAllocations from './leave_allocations/LeaveAllocations'
import {useIntl} from 'react-intl'
import LeaveApprovalTable from './leave_approvals/LeaveApprovalTable'
import TeamLeavesTableView from './team_leaves/TeamLeavesTableView'
const LeaveRoutes = () => {
  const leaveBreadCrumbs = [
    {
      title: 'Leave Management',
      path: '/leave-management/my-leaves',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const intl = useIntl()
  const {pathname} = useLocation()
  const [SideDrawerId, updateId] = useState('0')
  const switchMethod = () => {
    switch (pathname) {
      case '/leave-management/my-leaves':
        return (
          <>
            <PageTitle breadcrumbs={leaveBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.MY_LEAVES'})}
            </PageTitle>
            <MyLeavesNew />
          </>
        )

      case '/leave-management/leave-approvals':
        return (
          <>
            <PageTitle breadcrumbs={leaveBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.LEAVE_APPROVALS'})}
            </PageTitle>
            <LeaveApprovalTable />
          </>
        )

      case '/leave-management/leave-types':
        return (
          <>
            <PageTitle breadcrumbs={leaveBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.LEAVE_TYPES'})}
            </PageTitle>
            <LeaveTypesTable />
          </>
        )

      case '/leave-management/leave-eligibility':
        return (
          <>
            <PageTitle breadcrumbs={leaveBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.LEAVE_ELIGIBILITY'})}
            </PageTitle>
            <LeaveEligibility />
          </>
        )

      case '/leave-management/leave-allocations':
        return (
          <>
            <PageTitle breadcrumbs={leaveBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.LEAVE_ALLOCATIONS'})}
            </PageTitle>
            <LeaveAllocations />
          </>
        )

      case '/leave-management/leave-holidays':
        return (
          <>
            <PageTitle breadcrumbs={leaveBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.HR_CONFIGURATION.HOLIDAYS'})}
            </PageTitle>
            <HolidaysPageMain />
          </>
        )

      case '/leave-management/table_view':
        return (
          <>
            <PageTitle breadcrumbs={leaveBreadCrumbs}>
              {intl.formatMessage({id: 'LABEL.TEAM_LEAVES'})}
            </PageTitle>
            <TeamLeavesTableView />
          </>
        )

      default:
        return (
          <>
            <PageTitle breadcrumbs={leaveBreadCrumbs}>
              {intl.formatMessage({id: 'MYLEAVES'})}
            </PageTitle>
            <MyLeavesNew />
          </>
        )
    }
  }

  const UpdatingId = (id) => {
    updateId(id)
  }

  return (
    <>
      <Context.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        <LeaveNavbar />
        {switchMethod()}
      </Context.Provider>
    </>
  )
}

export default LeaveRoutes
